import React, { FunctionComponent, ReactNode } from "react";
import CardContainer from "../CardContainer";
import * as styles from "./CardAbout.module.scss";
import { SeparatorBackground } from "shared/types/enums";
import CardSeparator from "../CardSeparator";
import cn from "utils/classNames";

type Props = {
  title: string;
  children: ReactNode;
  header?: any;
  separatorBackground: SeparatorBackground;
};

const CardAbout: FunctionComponent<Props & React.HTMLAttributes<HTMLDivElement>> = ({title = "", header = "",
  separatorBackground = SeparatorBackground.Gradient1, className, children}) => {
  return (
    <CardContainer className={cn(styles.cardAbout, className)}>
      <div className={styles.cardAboutTitle}>{title}</div>
      {header}
      <CardSeparator separatorBackground={separatorBackground} className={styles.cardAboutSeparator} />
      <div className={styles.cardAboutText}>{children}</div>
    </CardContainer>
  );
};

export default CardAbout;
