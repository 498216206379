import React, { FunctionComponent } from "react";
import { SeparatorBackground } from "shared/types/enums";
import styles from "./CardSeparator.module.scss";

type Props = {
  className: string;
  separatorBackground: SeparatorBackground;
  small?: boolean;
};

const CardSeparator: FunctionComponent<Props> = ({ className, separatorBackground, small }) => {
  const separatorBackgroundClassName = `separator${separatorBackground}`;
  const smallVariant = small ? styles.separatorSmall : "";
  return (
    <div className={[styles.separator, className, styles[`${separatorBackgroundClassName}`], smallVariant].join(" ")} />
  );
};

export default CardSeparator;
