import React from "react";
import Footer from "sections/Footer";
import HeroInterview from "sections/Hero/HeroInterview";
import InterviewMain from "sections/InterviewMain/InterviewMain";

import dotsFaqLeft from "assets/svgs/faq-dots-left.svg";
import interview from "../../InterviewMockDB";
import indexStyles from "../Index.module.scss";

const InterviewPage = () => {
  // Interview page should not be public yet
  return <div></div>;

  return (
    <>
      <HeroInterview title={interview.title} />
      <InterviewMain
        interviewee={interview.interviewee}
        topics={interview.topics}
        estimatedTime={interview.estimatedTime}
      />
      <div className={indexStyles.sectionsFooter}>
        <img className={indexStyles.sectionsFooterDots} src={dotsFaqLeft} alt="" />
        <Footer />
      </div>
    </>
  );
};

export default InterviewPage;
