export default {
  title: "Front-end multiple choice assignement",
  interviewee: "Vlad",
  topics: ["HTML", "CSS", "Javascript/Typescript", "React"],
  estimatedTime: 30,
  questions: [
    {
      question: {
        type: "WithCode", //withoutCode
        language: "html",
        title: "What is wrong with the above piece of code?",
        editorCode: '<a href="//google.com/"><button type="button">Click me</button></a>',
      },
      answer: {
        type: "Multiple", //multiple, single, code
        language: null,
        answers: [
          "The href prop is missing a protocol (i.e. https)",
          "A button is not a valid child of an anchor",
          "External links should have ref=”noopener noreferrer",
          "Nothing, it’s perfect",
        ], //if answerType is code this will be null.
      },
    },
    {
      question: {
        type: "WithCode", //withoutCode
        language: "css",
        title: "Type the CSS to make the text red inside the button",
        editorCode:
          ".dots {display: flex;position: absolute;margin-left: 24px;margin-top: 24px; z-index: 1;.dot:not(:last-child) {margin-right: 8px;}}",
      },
      answer: {
        type: "Code", //multiple, single, code
        language: "css",
        answers: null,
      },
    },
    {
      question: {
        type: "WithoutCode", //withoutCode
        language: null,
        title: "Why should a label always be associated with an input?",
        editorCode: ";(function () { console.log(x) let x = 5 return x * 2})()",
      },
      answer: {
        type: "Multiple", //multiple, single, code
        language: null,
        answers: [
          "For screen readers to know which label belongs to each input",
          "It's required for HTML form validation",
          "So clicking it focuses the input",
          "Enables tab navigation to the content next input",
        ], //if answerType is code this will be null.
      },
    },
    {
      question: {
        type: "WithCode", //withoutCode
        language: "js",
        title: "What does the above function log?",
        editorCode: ";(function () { console.log(x); let x = 5; return x * 2})()",
      },
      answer: {
        type: "Single", //multiple, single, code
        language: null,
        answers: ["undefined", "5", "10", "Function throws error"], //if answerType is code this will be null.
      },
    },
  ],
};
