import React, { FunctionComponent, ReactNode, TouchEvent, useRef } from "react";
import cn from "utils/classNames";
import styles from "./CardContainer.module.scss";

type Props = {
  children: ReactNode;
  onClick?: () => void;
  onTouchStart?: (event: TouchEvent) => void;
  onTouchMove?: (event: TouchEvent) => void;
  onTouchEnd?: (event: TouchEvent) => void;
};

const CardContainer: FunctionComponent<Props & React.HTMLAttributes<HTMLElement>> = ({
  children,
  className,
  onClick,
  onTouchStart,
  onTouchMove,
  onTouchEnd,
}) => {
  const ref = useRef(null);
  return (
    <div
      className={cn(styles.cardContainer, className)}
      onClick={onClick}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      ref={ref}
    >
      {children}
    </div>
  );
};

export default CardContainer;
